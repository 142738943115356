import { PageProps } from "gatsby";
import React from "react";

import { Clefs } from "../components/pages/Clefs";

import { snProps } from "../js/utils";

const ClefsPage = (props: PageProps) => <Clefs {...{ ...snProps, ...props }} />;

export default ClefsPage;
